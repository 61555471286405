<template>
  <div class="card card-petition-stats text-black bg-warning mb-3 text-center" style="max-width: 18rem;">
    <div class="card-header">کل امضاها</div>
    <div class="card-body">
      <h1 class="card-title">{{ totalSigns }}</h1>
    </div>
  </div>
  <div class="card card-petition-stats text-black bg-warning mb-3 text-center" style="max-width: 18rem;">
    <div class="card-header">امضاهای تایید شده</div>
    <div class="card-body">
      <h1 class="card-title">{{ verifiedSigns }}</h1>
    </div>
  </div>
  <div v-if="pageVisits" class="card card-petition-stats text-black bg-info mb-3 text-center" style="max-width: 18rem;">
    <div class="card-header">دفعات مشاهده</div>
    <div class="card-body">
      <h1 class="card-title">{{ pageVisits }}</h1>
    </div>
  </div>
  <div v-if="currentVisitors" class="card card-petition-stats text-black bg-info mb-3 text-center" style="max-width: 18rem;">
    <div class="card-header">تعداد کاربر در این لحظه</div>
    <div class="card-body">
      <h1 class="card-title">{{ currentVisitors }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "PetitionStat",
  props: {
    config: Object
  },
  data(){
    return {
      totalSigns: 0,
      verifiedSigns: 0,
      pageVisits: null,
      currentVisitors: null,
    };
  },
  methods: {
    updateVote(){
      this.axios(this.config.site_base_url + '/api/stat/'+this.$route.params.id)
        .then( (response) => {
          this.totalSigns = response.data.totalSigns;
          this.verifiedSigns = response.data.verifiedSigns;
          this.pageVisits = response.data.pageVisits;
          this.currentVisitors = response.data.currentVisitors;
        })
    }
  },
  mounted(){
    this.updateVote();
  }
}
</script>
