import { createApp } from 'vue';
import axios from 'axios'
import VueAxios from 'vue-axios'

import router from '@/router';
import App from '@/App.vue';

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/css/style.css';
import config from './assets/data/config.json'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowsRotate, faAt, faHurricane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTwitter, faGithub, faFacebook,
          faTelegram, faLinkedin, faSkype,
          faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons'

library.add([faArrowsRotate, faAt, faHurricane,
             faTwitter, faGithub, faFacebook,
             faTelegram, faLinkedin, faSkype,
             faWhatsapp, faInstagram]);

axios.defaults.withCredentials = true;
axios.interceptors.response.use(undefined, (error) => {
  // Never gets here for network errors
  if(error.code !== 'ERR_NETWORK'){
    return Promise.reject(error);
  }

});

createApp(App,{ config: config })
  .component('fa-icon', FontAwesomeIcon)
  .use(VueAxios, axios)
  .use(router)
  .mount('#site-layout');
