<template>
  <section class="body">
    <div class="row mx-0">
      <div class="col-sm-12 col-md-2"></div>
      <div class="col-sm-12 col-md-8">
        <PetitionBody :petition="petition" />
        <TermsOfService />
        <SignPetition :petition="petition" :config="config" />
      </div>
      <div class="col-sm-12 col-md-2">
        <PetitionStat :config="config" />
      </div>
    </div>
  </section>
</template>

<script>
import PetitionBody from "@/components/PetitionBody.vue"
import TermsOfService from "@/components/TermsOfService.vue"
import SignPetition from "@/components/SignPetition.vue"
import PetitionStat from "@/components/PetitionStat.vue"

export default {
  name: 'PetitionLayout',
  props: {
    config: Object
  },
  data(){
    return {
      petition: {}
    }
  },
  components: {
    PetitionBody,
    TermsOfService,
    SignPetition,
    PetitionStat
  },
  methods: {
    getPetition(){
      this.axios(this.config.site_base_url + '/api/getPetition/'+this.$route.params.id)
        .then( (response) => {
          this.petition = response.data.petition;
        })
    }
  },
  mounted() {
    this.getPetition();
  }
}
</script>

<style>
</style>
