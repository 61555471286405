<template>
  <section class="body mb-4">
    <div class="row mx-0">
      <div class="col-sm-12 col-md-1"></div>
      <div class="col-sm-12 col-md-4">
        <div class="card text-bg-dark">
          <img src="/assets/images/40-days.jpg" class="card-img" alt="چهلم مهسا">
          <div class="card-img-overlay">
            <h5 class="card-title text-dark">درباره ما</h5>
            <div class="light-canvas">
              <p class="card-text text-dark">کشور ما سالهاست در تسخیر دشمن است. برای باز پس گیری وطن تا پای جان ایستاده‌ایم.</p>
              <p class="card-text text-dark"><small>پاینده باد ایران آزاد</small></p>
          </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-5">
        <p>
          امروز که کشور عزیزمان در دستان حکومتی خودکامه و ستمگر است بر آن شدیم که تمام توان خود را در راستای ایجاد یکصدایی ملت شریف ایران به کار گیریم.
        </p>
        <p>
          این پایگاه اینترنتی نتیجه پایمردی و تلاش متخصصین کشور عزیزمان در جهت تسهیل رای گیری‌هایی است که در طول انقلاب به آن نیاز خواهیم داشت و با توجه به نیازها و شرایط خاص کشور و با در نظر داشتن امنیت کاربران طراحی و پیاده سازی شده است.
        </p>
        <p>
          این پایگاه در شروع تنها برای اخذ رای مردمی به منظور درخواست از سیاست ورزان مطرح جهت ایجاد همبستگی و تشکیل شورای نمایندگی انقلاب مردمی به کار گرفته می‌شود و در طول زمان بر حسب نیاز امکانات بیشتری به آن اضافه می‌گردد.
        </p>
        <p>
          این سایت هیچ اطلاعات شخصی‌ای را از کاربران دریافت نمی‌کند و برای پیشگیری از رای دهی مکرر یک کد منحصر به فرد با آلگوریتم SHA-512 با استفاده از آدرس ایمیل شما ایجاد خواهد شد و قابل بازیابی معکوس نخواهد بود.
        </p>
        <p>
          توجه داشته باشید این سایت هیچ ایمیلی برای تبلیغات یا درخواست اطلاعات یا هر گونه وجوه مالی ارسال نخواهد کرد. در صورت دریافت چنین ایمیل‌هایی فورا آنها را حذف نمایید.
        </p>
        <p>
          این سایت تنها یک بار و پس از ثبت درخواست شما برای رای دهی ایمیلی را حاوی برگه رای ارسال می نماید که شما با تایید آن موافقت خود را با موضوع کارزار اعلام خواهید نمود.
        </p>
      </div>
      <div class="col-sm-12 col-md-2"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutLayout',
  props: {
  },
  components: {
  }
}
</script>

<style>
</style>
