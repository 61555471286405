<template>
  <div class="row mb-2">
    <div class="col-12">
      <div class="card sign-petition">
        <h5 class="card-header">اینجا رای دهید</h5>
        <div class="card-body">
          <h5 class="text-center text-danger" v-if="pClosed">
            این کارزار برای امضا باز نیست
          </h5>
          <!-- Showing Form -->
          <div v-else-if="unsubmitted" class="row gy-2 gx-3 align-items-top">
            <div class="col-sm-12 col-md-6 col-lg-3">
              <div class="input-group has-validation">
                <div class="input-group-text" ><fa-icon icon="fa-at" /></div>
                <input type="text" class="form-control"
                      :class="{'is-invalid':isInvalidEmail}"
                      :placeholder="emailLabel"
                      aria-describedby="validationServerEmailFeedback"
                      v-model="email" >
                <div id="validationServerEmailFeedback" class="invalid-feedback">آدرس ایمیل اشتباه است</div>
              </div>
            </div>
            <div class="col-sm-12 col-md-auto center">
              <div><img class="form-control captcha-image" v-bind:src="captchaUrl" /></div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <div class="input-group  has-validation">
                <div class="input-group-text" @click="renewCaptcha()"><fa-icon icon="fa-arrows-rotate" /></div>
                <input type="text" class="form-control"
                      :class="{'is-invalid':isInvalidCaptcha}"
                      :placeholder="captchaLabel"
                      aria-describedby="validationServerCaptchaFeedback"
                      v-model="captcha" >
                <div id="validationServerCaptchaFeedback" class="invalid-feedback">کد کپچا اشتباه است</div>
              </div>
            </div>
            <div class="col-sm-12 col-md-auto">
              <button class="btn btn-warning" @click="sendVote()">ارسال</button>
            </div>
          </div>
          <!-- Sending -->
          <div v-else-if="sending" class="spinner-section">
            <div class="spinner-border spinner-icon" role="status">
              <span><fa-icon icon="fa-hurricane fa-2x" /></span>
            </div>
            <strong>درحال ارسال ایمیل...</strong>
          </div>
          <!-- Successful Submission -->
          <h5 class="text-center text-success" v-else>
            درخواست شما برای امضای این کارزار با موفقیت ثبت شد. برای ثبت نهایی امضا پیامی به آدرس ایمیل شما ارسال شده است. لطفا هم اکنون به ایمیل خود مراجعه فرموده و امضای خود را تایید نمایید. اگر پیام را دریافت نکردید حتما پوشه اسپم را نیز بررسی فرمایید.
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignPetition',
  props: {
    petition: Object,
    config: Object
  },
  data() {
    return {
      captchaUrl: '',
      isInvalidEmail: false,
      isInvalidCaptcha: false,
      emailLabel: '',
      email: '',
      captchaLabel: '',
      captcha: '',
      unsubmitted: true,
      sending: false
    }
  },
  computed: {
    pClosed() {
      return (this.petition.start == null) || (this.petition.finish != null);
    }
  },
  methods: {
    resetEmail(){
      this.emailLabel = 'آدرس ایمیل';
      this.email = '';
    },
    resetCaptcha(){
      this.captchaLabel = 'کد کپچا';
      this.captcha = '';
      this.renewCaptcha();
    },
    renewCaptcha(){
      this.captchaUrl = this.config.site_base_url + '/api/getCaptcha?' + Math.floor(Math.random()*65535);
    },
    sendVote(){
      this.isInvalidEmail = false;
      this.isInvalidCaptcha = false;
      this.unsubmitted = false;
      this.sending = true;
      this.axios.post(this.config.site_base_url + '/api/recordVote',{
        petition : this.$route.params.id,
        email : this.email,
        captcha : this.captcha
      })
      .then( () => {
        this.unsubmitted = false;
        this.sending = false;
      })
      .catch((error)=>{
        if(error.response.data.message == "Invalid Email."){
          this.isInvalidEmail = true;
        }
        if(error.response.data.message == "Invalid Captcha."){
          this.isInvalidCaptcha = true;
        }
        this.resetCaptcha();
        this.unsubmitted = true;
        this.sending = true;
      });
    }
  },
  mounted(){
    this.resetEmail();
    this.resetCaptcha();
  }
}
</script>

<style>
</style>
