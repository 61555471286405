<template>
  <section class="body mb-4">
    <div class="row mx-0">
      <div class="col-sm-12 col-md-2"></div>
      <div class="col-sm-12 col-md-8">
        <h2>وضعیت کارزارها</h2>
        <p>برای مطالعه و رای دادن به کارزار مورد نظر خود آن را از فهرست زیر انتخاب نمایید.</p>
        <DataTable
          :columns="columns"
          :options="dtOptions"
          class="">
          <thead>
            <tr>
              <th data-priority="1">عنوان کارزار</th>
              <th data-priority="50">شروع</th>
              <th data-priority="50">پایان</th>
              <th data-priority="50">تعداد امضا</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </DataTable>
      </div>
      <div class="col-sm-12 col-md-2"></div>
    </div>
  </section>
</template>

<script>
import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-responsive-bs5'
DataTable.use(DataTableLib);

export default {
  name: 'HomeLayout',
  props: {
    config: Object
  },
  data() {
    return {
      dtOptions: {
        responsive: {
          breakpoints: [
            { name: 'mobile-p', width: 480 },
            { name: 'mobile-l', width: 768 }
          ]
        },
        processing: true,
        serverSide: true,
        scrollX: false,
        scrollY: false,
        language:{
          url: '/assets/datatables-fa.json'
        },
        ajax:{
          url: this.config.site_base_url + '/api/getPetitionsDT',
          type: 'post',
          dataType: 'json'
        },
        rowId: 'id',
      },
      columns: [
        { data: 'title', render: this.renderLink},
        { data: 'start', render: this.renderStart, class: 'rtl-numeric-center'},
        { data: 'finish', render: this.renderDate, class: 'rtl-numeric-center'},
        { data: 'votes', class: 'rtl-numeric-center'}
      ],
      columnDefs: [
        { width: "80%" , targets: 0 },
        { width: "10%" , targets: 0 },
        { width: "10%" , targets: 0 },
      ]
    }
  },
  computed:{

  },
  methods: {
    renderStart: function(data){
      const _date = this.renderDate(data)
      return _date ?? 'آغاز نشده'
    },
    renderDate: function(data){
      return data !== null ? new Intl.DateTimeFormat('fa-IR', { dateStyle: 'short', timeStyle: 'short'}).format(new Date(data.date)) : null
    },
    renderLink: function(data, type, row){
      return '<a href="'+ this.config.site_base_url +'/petition/'+row.id+'">'+data+'</a>'
    }
  },
  components: {
    DataTable
  }
}
</script>

<style>
@import 'datatables.net-bs5';
@import 'datatables.net-responsive-bs5';

table.display {
	width: 100% !important;
}

.datatable select {
	direction: ltr;
}

table.dataTable thead > tr > th.sorting::before, table.dataTable thead > tr > th.sorting::after, table.dataTable thead > tr > th.sorting_asc::before, table.dataTable thead > tr > th.sorting_asc::after, table.dataTable thead > tr > th.sorting_desc::before, table.dataTable thead > tr > th.sorting_desc::after, table.dataTable thead > tr > th.sorting_asc_disabled::before, table.dataTable thead > tr > th.sorting_asc_disabled::after, table.dataTable thead > tr > th.sorting_desc_disabled::before, table.dataTable thead > tr > th.sorting_desc_disabled::after, table.dataTable thead > tr > td.sorting::before, table.dataTable thead > tr > td.sorting::after, table.dataTable thead > tr > td.sorting_asc::before, table.dataTable thead > tr > td.sorting_asc::after, table.dataTable thead > tr > td.sorting_desc::before, table.dataTable thead > tr > td.sorting_desc::after, table.dataTable thead > tr > td.sorting_asc_disabled::before, table.dataTable thead > tr > td.sorting_asc_disabled::after, table.dataTable thead > tr > td.sorting_desc_disabled::before, table.dataTable thead > tr > td.sorting_desc_disabled::after {
  right: initial;
	left: 10px;
}

table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting_asc_disabled, table.dataTable thead > tr > th.sorting_desc_disabled, table.dataTable thead > tr > td.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting_asc_disabled, table.dataTable thead > tr > td.sorting_desc_disabled {
	padding-right: initial;
	padding-left: 26px;
}

table.dataTable thead th, table.dataTable thead td, table.dataTable tfoot th, table.dataTable tfoot td {
	text-align: right;
}

.rtl-numeric-center {
	direction: ltr;
  text-align: center !important;
}

</style>
