<template>
  <div class="row mb-2">
    <div class="col-12">
      <div class="card">
        <h5 class="card-header">{{ petition.title }}</h5>
        <div class="card-body">
          <h6 class="card-subtitle mb-4 text-muted">{{ petition.intro }}</h6>
          <div class="card-text" v-html="petition.text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PetitionBody',
    props: {
      petition: {}
    },
    data() {
      return {
        title: '',
        intro: '',
        text: ''
      }
    },
    methods: {
    },
    mounted() {
    }
  }
</script>

<style>
</style>
