<template>
  <div class="row mb-2">
    <div class="col-12">
      <div class="card">
        <h5 class="card-header">نتیجه ثبت رای</h5>
        <div class="card-body">
          <div class="card-text">
            <!-- Working -->
            <div v-if="working" class="spinner-section">
              <div class="spinner-border spinner-icon" role="status">
                <span><fa-icon icon="fa-hurricane fa-2x" /></span>
              </div>
              <strong>در حال ثبت رای ...</strong>
            </div>
            <!-- Successful Submission -->
            <div v-else-if="voteSuccess">
              <h5 class="text-center text-success">
                ثبت رای با موفقیت انجام شد. از همراهی شما سپاسگزاریم.
              </h5>
            </div>
            <!-- Failed Submission -->
            <div v-else>
              <h5 class="text-center text-danger">کد رای شما  یه یکی از این دلایل معتبر نیست:</h5>
              <ol>
                <li>قبلا با استفاده از همین لینک رای خود را تایید کرده‌اید و دیگر نیازی به تایید رای وجود ندارد.</li>
                <li>بیش از یک بار ایمیل خود را ثبت کرده‌اید، رای جدید باعث  ابطال این کد شده و کد جدید برایتان از طریق ایمیل ارسال شده است. منتظر ایمیل جدید بمانید.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VoteStatus",
  props: {
    config: Object
  },
  data(){
    return {
      working: true,
      voteSuccess: false
    };
  },
  methods: {
    verifyVote() {
      try {
        this.axios(this.config.site_base_url + '/api/verifyVote/' + this.$route.params.vote)
        .then(() => {
          this.voteSuccess = true;
          this.working = false;
        })
        .catch(() => {
          this.working = false;
        })
      } catch (e) {
        this.working = false;
      }
    }
  },
  mounted(){
    this.verifyVote();
  }
}
</script>
