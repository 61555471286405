<template>
    <HeaderSection />
    <router-view :config="config" />
    <FooterSection :config="config" />
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue'
import FooterSection from '@/components/FooterSection.vue'
export default {
  name: 'App',
  props: {
    config: Object
  },
  components: {
    HeaderSection,
    FooterSection
  }
}
</script>
