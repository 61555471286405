<template>
  <section class="footer">
    <div class="row mx-0 pb-4 pt-4">
      <div class="col-sm-12 col-md-4">
        <div class="card-group">
          <div class="card text-center">
            <a target="_BLANK" href="https://freeirancouncil.org"><img class="card-img-top" alt="" src="/assets/images/f2isc.jpg" /></a>
            <div class="card-body">
              <p class="card-text"><small class="text-light">شورای همبستگی آزادی برای ایران</small></p>
            </div>
          </div>
          <div class="card text-center">
            <a target="_BLANK" href="https://faranews.org"><img class="card-img-top" alt="" src="/assets/images/faranews.jpg" /></a>
            <div class="card-body">
              <p class="card-text"><small class="text-light">پایگاه خبری فرانیوز</small></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-8" style="direction: ltr; text-align:center;">
        <div class="social-wrapper">
          <span class="text-light social-media"><a target="_BLANK" href="https://twitter.com/Faranews2" class="link-light text-decoration-none"><fa-icon icon="fa-brands fa-twitter" class="fs-2" /> @Faranews2</a></span>
          <span class="text-light social-media"><a target="_BLANK" href="https://t.me/Faranews1401" class="link-light text-decoration-none"><fa-icon icon="fa-brands fa-telegram" class="fs-2" /> @Faranews1401</a></span>
          <span class="text-light social-media"><a target="_BLANK" href="https://twitter.com/FreeIranCouncil" class="link-light text-decoration-none"><fa-icon icon="fa-brands fa-twitter" class="fs-2" /> @FreeIranCouncil</a></span>
          <span class="text-light social-media"><a target="_BLANK" href="https://instagram.com/freeirancouncil" class="link-light text-decoration-none"><fa-icon icon="fa-brands fa-instagram" class="fs-2" /> @freeirancouncil</a></span>
        </div>
      </div>
    </div>
    <CreditsLine />
  </section>
</template>

<script>
import CreditsLine from '@/components/CreditsLine.vue';

export default {
  name: 'FooterSection',
  props: {
    config: Object
  },
  components:{
    CreditsLine
  },
  mounted(){
    if(this.config.plausible.enabled){
      const recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute("src", "https://analytics.changeforiran.org/js/script.js");
      recaptchaScript.setAttribute("data-domain", this.config.plausible.domain);
      recaptchaScript.setAttribute("defer", "defer");
      document.body.appendChild(recaptchaScript);
    }
  }
}
</script>
