import { createRouter, createWebHistory } from "vue-router";
import HomeLayout from '@/HomeLayout.vue';
import AboutLayout from '@/AboutLayout.vue';
import PetitionLayout from '@/PetitionLayout.vue';
import VoteLayout from '@/VoteLayout.vue';

const routes = [
  {path: "/", name: "Home", component: HomeLayout},
  {path: "/about", name: "About", component: AboutLayout},
  {path: "/petition/:id", name: "Petition", component: PetitionLayout},
  {path: "/verifyVote/:id/:vote", name: "Vote", component: VoteLayout},
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('site-layout').scrollIntoView({ behavior: 'smooth' });
  }
});

export default router;
