<template>
  <div class="row mb-2">
    <div class="col-12">
      <div class="card">
        <h5 class="card-header">شرایط</h5>
        <div class="card-body">
          <div class="card-text">
            <ol class="list-group list-group-numbered">
              <li class="list-group-item">
                این سایت هیچ اطلاعاتی را از کاربران ذخیره نمی کند، فقط آدرس ایمیل شما به صورت رمز شده و به منظور پیشگیری از امضای مکرر نگهداری می شود
              </li>
              <li class="list-group-item">
                این سایت تنها برای اخذ رای شما ایمیل ارسال می کند و هر ایمیلی با محتوای متفرقه نامعتبر است
              </li>
              <li class="list-group-item">
                این سایت تحت هیچ شرایطی از شما درخواست اطلاعات نخواهد نمود درخواستهایی از این نوع همگی نامعتبر است
              </li>
              <li class="list-group-item">
                تلاش کرده‌ایم مانع ثبت رای از طریق ایمیلهای نامعتبر شویم. بنابر این در صورتی که از ایمیلهای موقت استفاده می‌کنید، سیستم احتمالا از ثبت رای جلوگیری کرده و به شما پیغام خطای ایمیل نامعتبر را نمایش می‌دهد.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
